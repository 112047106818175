import React from 'react';
import styled from 'styled-components';
import { FaBars, FaSignOutAlt, FaTimes, FaUser, FaBell, FaComments, FaCog } from 'react-icons/fa';

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f4f6f8;
  color: #2c3e50;
  padding: 0.5rem 1rem 0rem 1rem;
`;

const LeftSection = styled.div`
  display: flex;
  align-items: center;
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;
`;

const Logo = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: 1rem;
  color: #2c3e50;
  user-select: none;
`;

const MenuButton = styled.button`
  background: none;
  border: none;
  color: #2c3e50;
  font-size: 1.2rem;
  cursor: pointer;
  padding: 0.5rem;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }
`;

const IconButton = styled.button`
  background: none;
  border: none;
  color: #2c3e50;
  font-size: 1.2rem;
  cursor: pointer;
  padding: 0.5rem;
  margin-left: 0.5rem;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }
`;

function TopBar({ onMenuOpen, onMenuClose, onLogout, isMenuOpen, onSidebarOpen }) {
  const handleMenuToggle = () => {
    if (isMenuOpen) {
      onMenuClose();
    } else {
      onMenuOpen();
    }
  };

  return (
    <TopWrapper>
      <LeftSection>
        <MenuButton onClick={handleMenuToggle}>
          {isMenuOpen ? <FaTimes /> : <FaBars />}
        </MenuButton>
        <Logo>ORDARS</Logo>
      </LeftSection>
      <RightSection>
        <IconButton onClick={() => onSidebarOpen('my')} title='마이페이지'>
          <FaUser />
        </IconButton>
        <IconButton onClick={() => onSidebarOpen('notifications')} title='알림'>
          <FaBell />
        </IconButton>
        <IconButton onClick={() => onSidebarOpen('chat')} title='채팅'>
          <FaComments />
        </IconButton>
        <IconButton onClick={() => onSidebarOpen('settings')} title='설정'>
          <FaCog />
        </IconButton>
        <IconButton onClick={onLogout} title='로그아웃'>
          <FaSignOutAlt />
        </IconButton>
      </RightSection>
    </TopWrapper>
  );
}

export default TopBar;