// 환경 변수에서 API 기본 URL을 가져옵니다. 기본값으로 프로덕션 URL을 사용합니다.
const API_BASE_URL = process.env.REACT_APP_API_URL || (process.env.NODE_ENV === 'production' 
  ? '/api' // 클러스터 내부의 nginx 에서 http://ordars-api-service 로 프록시
  : 'https://api.ordars.com');

console.log("- API_BASE_URL : ",API_BASE_URL);

export const ENDPOINTS = {
  PUSH: {
    SEND: `${API_BASE_URL}/push/send`,
  },
  AUTH: {
    LOGIN: `${API_BASE_URL}/auth/login`,
    LOGOUT: `${API_BASE_URL}/auth/logout`,
    SIGNUP: `${API_BASE_URL}/auth/signup`,
    GOOGLE: {
      INIT: `${API_BASE_URL}/auth/google`,
      CALLBACK: `${API_BASE_URL}/auth/google/callback`,
    },
    KAKAO: {
      INIT: `${API_BASE_URL}/auth/kakao`,
      CALLBACK: `${API_BASE_URL}/auth/kakao/callback`,
    },
    NAVER: {
      INIT: `${API_BASE_URL}/auth/naver`,
      CALLBACK: `${API_BASE_URL}/auth/naver/callback`,
    },
  },
  USERS:{
    INFO: `${API_BASE_URL}/users/info`,
    UPDATE: `${API_BASE_URL}/users/update`,
  }
};

// 필요한 경우 동적 엔드포인트를 생성하는 함수를 추가할 수 있습니다.
export const createDynamicEndpoint = (baseEndpoint, param) => `${baseEndpoint}/${param}`;

// 예: 특정 사용자 정보를 가져오는 동적 엔드포인트
// export const getUserInfoEndpoint = (userId) => createDynamicEndpoint(ENDPOINTS.AUTH.USER_INFO, userId);