import React from 'react';
import styled from 'styled-components';

const BottomWrapper = styled.div`
  background-color: #a5becf;
  height: ${props => props.$isOpen ? '60px' : '0px'};
  transition: height 0.3s ease;
  overflow: hidden;
`;

const CloseButton = styled.button`
  float: right;
  background: none;
  border: none;
  color: white;
  font-size: 18px;
  cursor: pointer;
  padding: 5px 10px;
`;

const Content = styled.div`
  padding: 10px;
`;

function Bottom({ $isOpen, onClose }) {
  if (!$isOpen) return null;

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <BottomWrapper $isOpen={$isOpen}>
      <CloseButton onClick={handleClose}>×</CloseButton>
      <Content>
        {/* 하단 영역 내용 */}
        하단 영역 내용입니다.
      </Content>
    </BottomWrapper>
  );
}

export default Bottom;