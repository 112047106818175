import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { ENDPOINTS } from '../../../api/endpoints';
import { useLayout } from '../../../contexts/LayoutContext';

const MyContainer = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 24px;
  max-width: 500px;
  margin: 0 auto;
`;

const Title = styled.h2`
  color: #333;
  font-size: 24px;
  margin-bottom: 20px;
  border-bottom: 2px solid #f0f0f0;
  padding-bottom: 10px;
`;

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const Label = styled.span`
  font-weight: bold;
  color: #666;
  width: 100px;
`;

const Value = styled.span`
  color: #333;
  flex-grow: 1;
`;

const EditButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #4a90e2;
  margin-left: 10px;
  display: flex;
  align-items: center;
  font-size: 14px;

  &:hover {
    text-decoration: underline;
  }
`;

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
`;

const Button = styled.button`
  padding: 8px 16px;
  margin-right: 10px;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #3a7bc8;
  }
`;

const CustomContentStyle = {
  padding: '20px',
  backgroundColor: '#f8f9fa',
  borderRadius: '8px'
};

function My(refreshKey) {
  const [userInfo, setUserInfo] = useState(null);
  const [error, setError] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [editField, setEditField] = useState('');
  const [editValue, setEditValue] = useState('');
  const { layoutState, handlers } = useLayout();

  useEffect(() => {
    fetchUserInfo(); // 
  }, [refreshKey]); // 사이드바 새로고침

  const fetchUserInfo = async () => {
    try {
      const response = await axios.get(ENDPOINTS.USERS.INFO, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      setUserInfo(response.data);
    } catch (err) {
      console.error('사용자 정보 가져오기 실패:', err);
      setError('사용자 정보를 불러오는 데 실패했습니다.');
    }
  };

  const getProfileValue = (key) => {
    if (!userInfo || !userInfo.profile || userInfo.profile.length === 0) return '-';
    const value = userInfo.profile[0]?.[key];
    return value !== undefined && value !== null ? value : '-';
  };

  const getShortId = (id) => {
    if (!id || typeof id !== 'string') return '-';
    return id.slice(-8);
  };

  const onEdit = (field) => {
    const newValue = field === 'email' ? userInfo.email : getProfileValue(field);
    handlers.openModal({
      title: `${field.charAt(0).toUpperCase() + field.slice(1)} 변경`,
      content: (
        <Input 
          defaultValue={newValue}
          onChange={(e) => layoutState.setModalState(prev => ({ ...prev, editValue: e.target.value }))}
          placeholder={`새 ${field} 입력`}
        />
      ),
      cancelButton: "취소",
      confirmButton: "변경",
      customContentStyle: CustomContentStyle,
      onConfirm: () => handleSave(field, layoutState.modalState.editValue)
    });
  };

  const handleSave = async () => {
    try {
      let endpoint = `${ENDPOINTS.USERS.UPDATE}/${editField}`;
      let data = { [editField]: editValue };
      
      if (editField !== 'email') {
        endpoint = `${ENDPOINTS.USERS.UPDATE}/profile`;
        data = { profile: [{ ...userInfo.profile[0], [editField]: editValue }] };
      }

      await axios.put(endpoint, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });

      await fetchUserInfo(); // Refresh user info
      setIsEditing(false);
    } catch (err) {
      console.error('정보 업데이트 실패:', err);
      setError('정보 업데이트에 실패했습니다.');
    }
  };

  if (error) {
    return <div>{error}</div>;
  }

  if (!userInfo) {
    return <div>Loading...</div>;
  }

  return (
    <MyContainer>
      <Title>내 정보</Title>
      <InfoItem>
        <Label>ID</Label>
        <Value>...{getShortId(userInfo._id)}</Value>
      </InfoItem>      
      <InfoItem>
        <Label>이메일</Label>
        <Value>{userInfo.email}</Value>
        <EditButton onClick={() => onEdit('email')}>          
          변경
        </EditButton>
      </InfoItem>
      <InfoItem>
        <Label>이름</Label>
        <Value>{getProfileValue('name')}</Value>        
      </InfoItem>
      <InfoItem>
        <Label>닉네임</Label>
        <Value>{getProfileValue('nickname')}</Value>
        <EditButton onClick={() => onEdit('nickname')}>          
          변경
        </EditButton>
      </InfoItem>
      <InfoItem>
        <Label>연락처</Label>
        <Value>{getProfileValue('phone')}</Value>
        <EditButton onClick={() => onEdit('phone')}>          
          변경
        </EditButton>
      </InfoItem>
    </MyContainer>
  );
}

export default My;