import React from 'react';
import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const CenterLayer = styled.div`
  background-color: white;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: calc(100% - 20px);  // 전체 너비에서 20px(좌우 각각 10px) 뺌
  user-select: none;

  @media (max-width: 500px) {
    max-width: calc(100% - 20px);  // 모바일에서도 최대 너비 제한
    margin: 0 10px;  // 좌우 10px 여백 추가
  }
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const ModalTitle = styled.h2`
  font-size: 24px;
  color: #2c3e50;
  margin: 0;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: #7f8c8d;
  font-size: 20px;
  cursor: pointer;
  transition: color 0.2s ease;

  &:hover {
    color: #34495e;
  }
`;

const ModalContent = styled.p`
  font-size: 16px;
  color: #34495e;
  margin-bottom: 30px;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Button = styled.button`
  background-color: ${props => props.$primary ? '#3498db' : 'transparent'};
  color: ${props => props.$primary ? 'white' : '#3498db'};
  border: ${props => props.$primary ? 'none' : '2px solid #3498db'};
  padding: 10px 20px;
  margin-left: 10px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${props => props.$primary ? '#2980b9' : 'rgba(52, 152, 219, 0.1)'};
  }
`;

const Modal = ({ 
  isOpen, 
  onClose, 
  title, 
  content, 
  cancelButton = '취소', 
  confirmButton = '확인', 
  onConfirm,
  closeOnOverlayClick = false, // 오버레이 클릭 시 닫히게 할지(true) 여부 
  customContentStyle = {} // 셀프 컨텐츠 스타일 추가
}) => {
  if (!isOpen) return null;

  const handleOverlayClick = (e) => {
    if (closeOnOverlayClick && e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <Overlay onClick={handleOverlayClick}>
      <CenterLayer onClick={e => e.stopPropagation()}>
        <ModalHeader>
          <ModalTitle>{title}</ModalTitle>
          <CloseButton onClick={onClose}>
            <FaTimes />
          </CloseButton>
        </ModalHeader>
        <div style={customContentStyle}>
          {content}
        </div>
        <ButtonGroup>
          <Button onClick={onClose}>{cancelButton}</Button>
          <Button $primary onClick={onConfirm}>{confirmButton}</Button>
        </ButtonGroup>
      </CenterLayer>
    </Overlay>
  );
};

export default Modal;