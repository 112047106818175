import { useState, useRef, useEffect, useCallback } from 'react';
import { useNavigate, useLocation, useNavigationType } from 'react-router-dom';

export default function useNavigationHandlers(layoutState) {
  const navigate = useNavigate();
  const location = useLocation();
  const isPopStateRef = useRef(false);
  const [closeStack, setCloseStack] = useState([]);  
  const navigationType = useNavigationType();  
  
  useEffect(() => { // 뒤로가기 버튼 클릭시 호출
    const handlePopState = (event) => {      
      if(isPopStateRef.current){
        isPopStateRef.current = false;
        return;
      }      
      if (closeStack.length > 0) {
        const lastItem = closeStack[closeStack.length - 1];        
        lastItem.func();
        setCloseStack(prev => prev.slice(0, -1));        
      }
    };
    if(navigationType=="POP"){ // 뒤로가기 버튼 클릭시 호출
    }else if(navigationType=="PUSH"){ // 페이지 이동시 호출
    }
    
    window.addEventListener('popstate', handlePopState);    
    return () => {      
      window.removeEventListener('popstate', handlePopState);      
    };
  }, [closeStack]);

  // 뒤로가기 버튼 클릭시 호출
  const addToCloseStack = (closeFunction, type) => {    
    setCloseStack(prev => [...prev, { func: closeFunction, type }]);
  };

  // 뒤로가기 버튼 클릭시 호출
  const removeFromCloseStack = (type) => {
    setCloseStack(prev => {
      const index = prev.findLastIndex(item => item.type === type);
      if (index !== -1) {
        return [...prev.slice(0, index), ...prev.slice(index + 1)];
      }
      return prev;
    });
  };

  const wrapLayerOpen = () => {
    if (layoutState.wrapLayers.length < 10) {
      const newLayer = { $type: 'wrap', id: Date.now() };
      layoutState.setWrapLayers(prev => [...prev, newLayer]);
      navigate(location.pathname);
      addToCloseStack(() => closeWrapLayer(true), 'wrapLayer');
    }
  };

  const closeWrapLayer = (backBtn = false) => {
    layoutState.setWrapLayers(prev => prev.slice(0, -1));
    if(!backBtn) {
      isPopStateRef.current = true;
      navigate(-1);
      removeFromCloseStack('wrapLayer');
    }
  };

  const mainLayerOpen = () => {
    if (layoutState.mainLayers.length < 10) {
      const newLayer = { $type: 'main', id: Date.now() };
      layoutState.setMainLayers(prev => [...prev, newLayer]); 
      navigate(location.pathname);
      addToCloseStack(() => closeMainLayer(true), 'mainLayer');
    }
  };

  const closeMainLayer = (backBtn = false) => {
    layoutState.setMainLayers(prev => prev.slice(0, -1));
    if(!backBtn) {
      isPopStateRef.current = true;
      navigate(-1);
      removeFromCloseStack('mainLayer');
    }
  };

  const openMenu = () => {
    layoutState.setIsMenuOpen(true);
    navigate(location.pathname);
    addToCloseStack(() => closeMenu(true), 'menu');
  };

  const closeMenu = (backBtn = false) => {
    layoutState.setIsMenuOpen(false);
    if (!backBtn) {
      isPopStateRef.current = true;
      navigate(-1);
      removeFromCloseStack('menu');
    }
  };

  const openBottom = () => {
    layoutState.setIsBottomOpen(true);
    navigate(location.pathname);
    addToCloseStack(() => closeBottom(true), 'bottom');
  };

  const closeBottom = (backBtn = false) => {
    layoutState.setIsBottomOpen(false);
    if (!backBtn) {
      isPopStateRef.current = true;
      navigate(-1);
      removeFromCloseStack('bottom');
    }
  };

  const openSidebar = (icon = 'profile', type = null) => {
    if (!layoutState.isSidebarOpen) { // 사이드바가 열려있지 않으면 열기
      navigate(location.pathname);
      addToCloseStack(() => closeSidebar(true), 'sidebar');
    }

    if(window.innerWidth >= 1000){ // 데스크탑 버전
      // type = self 란 sidebar 에서 직접 아이콘을 클릭했을때는 sidebar 를 닫지 않음
      if(layoutState.lastSidebarIcon == icon && type != 'self'){
        layoutState.setIsSidebarOpen(false);
        layoutState.setLastSidebarIcon(null);
      }else{ // 이전 아이콘과 다르면 열기
        layoutState.setIsSidebarOpen(true);
        layoutState.setLastSidebarIcon(icon);
        layoutState.setActiveSidebarIcon(icon);
        layoutState.setSidebarRefreshKey(prev => prev + 1); // 항상 새로운 값으로 업데이트
      }
    }else{ // 모바일 버전
      layoutState.setIsSidebarOpen(true);
      layoutState.setLastSidebarIcon(icon);
      layoutState.setActiveSidebarIcon(icon);
      layoutState.setSidebarRefreshKey(prev => prev + 1); // 항상 새로운 값으로 업데이트
    }
  };
  
  const closeSidebar = (backBtn = false) => {    
    layoutState.setIsSidebarOpen(false);
    layoutState.setLastSidebarIcon(null);
    if (!backBtn) {
      isPopStateRef.current = true;
      navigate(-1);
      removeFromCloseStack('sidebar');
    }
  };

  const openPopup = (message, title) => {
    layoutState.setPopup({ message, title });
    navigate(location.pathname);
    addToCloseStack(() => closePopup(true), 'popup');
  };

  const closePopup = (backBtn = false) => {
    layoutState.setPopup(null);
    if (!backBtn) {
      isPopStateRef.current = true;
      navigate(-1);
      removeFromCloseStack('popup');
    }
  };

  const openModal = useCallback((options) => {    
    layoutState.setModalState(prevState => ({ ...prevState, isOpen: true, ...options, customContentStyle: options.customContentStyle || {} }));
    navigate(location.pathname);
    addToCloseStack(() => closeModal(true), 'modal');    
  }, [layoutState]);

  const closeModal = useCallback((backBtn = false) => {
    layoutState.setModalState(prevState => ({ ...prevState, isOpen: false }));
  
    if (!backBtn) {
      isPopStateRef.current = true;
      navigate(-1);
      removeFromCloseStack('modal');
    }    
  }, [layoutState, navigate, removeFromCloseStack]);
  

  return {
    addToCloseStack,
    removeFromCloseStack,
    openMenu,
    closeMenu,
    openBottom,
    closeBottom,
    openPopup,
    closePopup,
    openSidebar,
    closeSidebar,
    wrapLayerOpen,
    closeWrapLayer,
    mainLayerOpen,
    closeMainLayer,
    openModal,
    closeModal,
  };
}