import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body, p, h1, h2, h3, h4, h5, h6, ul, ol, li, dl, dt, dd, table, th, td, form, fieldset, legend, input, textarea, button, select {
    margin: 0;
    padding: 0;
  }

  body, input, textarea, select, button, table {
    font-size: 12px;
    color: #2e2e2e;
    line-height: 100%;
    outline: none;
  }

  /* 여기에 나머지 전역 스타일을 추가하세요 */
`;

export default GlobalStyle;