import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { LayoutProvider } from './contexts/LayoutContext';
import Layout from './components/Layout';
import Login from './components/Auth/Login';
import AuthCallback from './components/Auth/AuthCallback';
import GlobalStyle from './styles/GlobalStyle';

function MainApp() {
  const { user } = useAuth();

  return (
    <>
      <GlobalStyle />
      <LayoutProvider>
        <Routes>
          <Route 
            path="/login" 
            element={user ? <Navigate to="/" replace /> : <Login />} 
          />
          <Route 
            path="/" 
            element={
              user ? (
                <Layout>
                  <h1>ORDARS의 방문을 환영합니다, {user.username}!</h1>
                  {/* 여기에 메인 컨텐츠를 추가하세요 */}
                </Layout>
              ) : (
                <Navigate to="/login" replace />
              )
            } 
          />
          <Route 
            path="/auth/callback" 
            element={<AuthCallback />} 
          />
        </Routes>
      </LayoutProvider>
    </>
  );
}

function App() {
  return (
    <AuthProvider>
      <MainApp />
    </AuthProvider>
  );
}

export default App;