import React, { useState, useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { IoChevronBack } from "react-icons/io5";
import { useNavigate, useLocation } from 'react-router-dom';
import { ENDPOINTS } from '../../api/endpoints';
import { useAuth } from '../../contexts/AuthContext';
import useLayoutState from '../../hooks/useLayoutState';
import useNavigationHandlers from '../../hooks/useNavigationHandlers';
import SignUp from './SignUp'; 
import logoImage from '../../images/logo.png';

const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const LoginWrapper = styled.div`  
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: ${gradientAnimation} 15s ease infinite;
`;

const LoginForm = styled.form`
  position: relative;
  background: rgba(255, 255, 255, 0.9);
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  width: 100%;
  max-width: 400px;

  @media (max-width: 500px) {
    width: calc(100% - 20px);    
  }
`;

const Title = styled.h2`
  margin-bottom: 40px;
  color: #333;
  text-align: center;
  font-size: 1.5rem;
  user-select: none;
`;

const InputGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  color: #555;
  user-select: none;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  transition: border-color 0.1s;
  box-sizing: border-box;

  &:focus {
    border-color: #23a6d5;
    outline: none;
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 15px 10px;
  background-color: #23a6d5;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.1s;
  user-select: none;

  &:hover {
    background-color: #1c8ab9;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const SnsLoginWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

const SnsButton = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 5px;
  border: none;
  background-color: ${props => props.$bgcolor};
  color: ${props => props.$bgcolor === '#FEE500' ? '#000000' : 'white'};
  font-weight: bold;
  margin-left: 10px;
  cursor: pointer;
  transition: opacity 0.1s;
  user-select: none;

  &:hover {
    opacity: 0.8;
  }
`;

const ErrorMessage = styled.p`
  color: #e73c7e;
  font-size: 0.9rem;
  margin-top: 5px;
`;

const ReadOnlyInput = styled(Input)`
  background-color: #f0f0f0;
  color: #666;
  cursor: not-allowed;
`;

const LogoWrapper = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

const Logo = styled.div`
  background: url(${logoImage}) #fff center no-repeat;
  background-size: 50%;
  border-radius: 50%;
  width: 70px; 
  height: 70px;
  margin-bottom: 10px;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);  
`;

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SignUpText = styled.p`
  text-align: center;
  margin: 20px 0;
  color: #555;
  font-size: 0.9rem;
`;

const SignUpLink = styled.a`
  color: #23a6d5;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const SuggestionList = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid #ddd;
  border-top: none;
  list-style-type: none;
  margin: 0;
  padding: 0;
  max-height: 150px;
  overflow-y: auto;
  z-index: 1000;
`;

const SuggestionItem = styled.li`
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const SuggestionText = styled.span`
  flex-grow: 1;
`;

const RemoveButton = styled.button`
  background: none;
  border: none;
  color: #999;
  font-size: 18px;
  cursor: pointer;
  padding: 0 5px;

  &:hover {
    color: #333;
  }
`;
const BackIcon = styled(IoChevronBack)`
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 24px;
  cursor: pointer;
  z-index: 1000;
`;

function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const layoutState = useLayoutState();  
  const navigationHandlers = useNavigationHandlers(layoutState);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const passwordInputRef = useRef(null);
  const [emailError, setEmailError] = useState('');
  const [step, setStep] = useState(1);
  const [showSignUp, setShowSignUp] = useState(false);
  const { login } = useAuth();
  const [error, setError] = useState('');  
  const [previousEmails, setPreviousEmails] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  useEffect(() => {
    // 로컬 스토리지에서 이전 이메일 주소들을 불러옵니다
    const storedEmails = JSON.parse(localStorage.getItem('previousEmails') || '[]');
    setPreviousEmails(storedEmails);

  }, []);

  useEffect(() => {
    // URL 파라미터에서 step 값을 읽어옵니다.
    const params = new URLSearchParams(location.search);
    const stepParam = params.get('step');
    if (stepParam) {
      setStep(parseInt(stepParam, 10));
    }
  }, [location]);

  useEffect(() => {
    if (step === 2 && passwordInputRef.current) {
      passwordInputRef.current.focus();
    }
  }, [step]);

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(e.target.value);
    setEmailError(validateEmail(e.target.value) ? '' : '유효한 이메일 주소를 입력해주세요.');
    setShowSuggestions(newEmail.length > 0);
  };

  const handleEmailSelect = (selectedEmail) => {
    setEmail(selectedEmail);
    setShowSuggestions(false);
  };

  const handleContinue = (e) => {
    e.preventDefault();
    if (validateEmail(email)) {     
      setStep(2);
      setPassword(''); // 비밀번호 입력 화면으로 넘어갈 때 password를 초기화
      navigate(`${location.pathname}?step=2`);      
      navigationHandlers.addToCloseStack(() => handleBackToEmailInput(true), 'loginStep');
    } else {
      setEmailError('유효한 이메일 주소를 입력해주세요.');
    }
  };

  const handleBackToEmailInput = (backBtn = false) => {
    setStep(1);
    setPassword(''); // 이메일 입력 화면으로 돌아갈 때도 password를 초기화
    if (!backBtn) {
      navigate(-1);
      navigationHandlers.removeFromCloseStack('loginStep');
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await login(email, password);
      // 로그인 성공 시 이메일 주소를 저장합니다
      saveSuccessfulEmail(email);
      navigate('/');
    } catch (error) {
      setError(error.message || '로그인에 실패했습니다.');
    }
  };

  const saveSuccessfulEmail = (email) => {
    const newPreviousEmails = [...previousEmails, email]
      .filter((value, index, self) => self.indexOf(value) === index) // 중복 제거
      .slice(-5); // 최근 5개만 유지
    setPreviousEmails(newPreviousEmails);
    localStorage.setItem('previousEmails', JSON.stringify(newPreviousEmails));
  };

  const handleRemoveEmail = (emailToRemove) => {
    const updatedEmails = previousEmails.filter(email => email !== emailToRemove);
    setPreviousEmails(updatedEmails);
    localStorage.setItem('previousEmails', JSON.stringify(updatedEmails));
  };

  const handleSnsLogin = async (provider) => {
    try {
      let authUrl;
      const storedProvider = localStorage.getItem('provider');
      const storedProviderId = localStorage.getItem('providerId');
      let prompt;
  
      switch (provider) {
        case 'Google':
          prompt = 'consent'; // 로그인 + 개인정보제공 동의
          if (storedProvider === 'google' && storedProviderId) {
            prompt = 'select_account'; // 로그인만 동의
          }
          authUrl = `${ENDPOINTS.AUTH.GOOGLE.INIT}?prompt=${prompt}`;
          break;
        case 'Kakao':
          prompt = 'login';  // 기본값: 로그인 화면 표시
          if (storedProvider !== 'kakao' || !storedProviderId) {
            //prompt = 'create';  // 계정 생성 화면 표시 (개인정보 제공 동의 포함)
            // 회원가입 기능은 필요없음
            prompt = 'login';
          }
          authUrl = `${ENDPOINTS.AUTH.KAKAO.INIT}?prompt=${prompt}`;
          break;
        case 'Facebook':
          break;
        case 'Naver':
          authUrl = `${ENDPOINTS.AUTH.NAVER.INIT}`;
          break;
        default:
          throw new Error('지원하지 않는 로그인 방식입니다.');
      }
  
      if (authUrl) {
        window.location.href = authUrl;
      }
    } catch (error) {
      setError(error.message || `${provider} 로그인에 실패했습니다.`);
    }
  };

  const handleShowSignUp = () => {
    setShowSignUp(true);
    navigate(`${location.pathname}?signup=true`);
    navigationHandlers.addToCloseStack(() => handleBackFromSignUp(true), 'signUp');
  };
  
  const handleBackFromSignUp = (isFromPopState = false) => {
    setShowSignUp(false);
    if (!isFromPopState) {
      navigate(-1);
    }
    navigationHandlers.removeFromCloseStack('signUp');
  };

  if (showSignUp) {
    return <SignUp onBack={handleBackFromSignUp} />;
  }



  return (
    <LoginWrapper>
      <LoginContainer>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
        <LoginForm onSubmit={step === 1 ? handleContinue : handleLogin}>
          {(step === 2) && (
            <BackIcon onClick={handleBackToEmailInput} />
          )}                
          <Title>로그인</Title>
          {step === 1 ? (
            <>
              <InputGroup>
                <Label htmlFor="email">이메일주소</Label>
                <div style={{ position: 'relative' }}>
                  <Input
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    onChange={handleEmailChange}
                    onFocus={() => setShowSuggestions(true)}
                    onBlur={() => setTimeout(() => setShowSuggestions(false), 200)}            
                    placeholder="이메일주소를 입력하세요"
                    required
                    autoComplete="off" // 자동완성 기능 끄기
                    autoCapitalize="none" // 첫 글자 대문자 변환 방지 (모바일에서 유용)
                    autoCorrect="off" // 자동 수정 기능 끄기
                  />
                  {showSuggestions && previousEmails.length > 0 && (
                    <SuggestionList>
                      {previousEmails
                        .filter(prevEmail => prevEmail.includes(email))
                        .map((prevEmail, index) => (
                          <SuggestionItem key={index}>
                            <SuggestionText onClick={() => handleEmailSelect(prevEmail)}>
                              {prevEmail}
                            </SuggestionText>
                            <RemoveButton
                              onClick={(e) => {
                                e.stopPropagation();
                                handleRemoveEmail(prevEmail);
                              }}
                            >
                              ×
                            </RemoveButton>
                          </SuggestionItem>
                        ))}
                    </SuggestionList>
                  )}
                </div>
                {emailError && <ErrorMessage>{emailError}</ErrorMessage>}
              </InputGroup>
              <Button type="submit" disabled={!validateEmail(email)}>계속</Button>
              <SignUpText>
                계정이 없으신가요? <SignUpLink onClick={handleShowSignUp}>회원가입</SignUpLink>
              </SignUpText>
              <SnsLoginWrapper>
                <SnsButton type="button" $bgcolor="#DB4437" onClick={() => handleSnsLogin('Google')}>G</SnsButton>
                <SnsButton type="button" $bgcolor="#4267B2" onClick={() => handleSnsLogin('Facebook')}>F</SnsButton>
                <SnsButton type="button"$bgcolor="#1EC800" onClick={() => handleSnsLogin('Naver')}>N</SnsButton>
                <SnsButton type="button"$bgcolor="#FEE500" onClick={() => handleSnsLogin('Kakao')}>K</SnsButton>
              </SnsLoginWrapper>
            </>
          ) : (
            <>
              <InputGroup>
                <Label htmlFor="email-readonly">이메일</Label>
                <ReadOnlyInput
                  type="email"
                  id="email-readonly"
                  value={email}
                  readOnly
                />
                {error && <ErrorMessage>{error}</ErrorMessage>}
              </InputGroup>
              <InputGroup>
                <Label htmlFor="password">비밀번호</Label>
                <Input
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="비밀번호를 입력하세요"
                  required
                  ref={passwordInputRef}
                />
              </InputGroup>
              <Button type="submit" disabled={!password}>로그인</Button>
            </>
          )}
        </LoginForm>
      </LoginContainer>
    </LoginWrapper>
  );
}

export default Login;