import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

function AuthCallback() {
    const navigate = useNavigate();
    const location = useLocation();
    const { handleSnsCallback } = useAuth();
  
    useEffect(() => {
      const params = new URLSearchParams(location.search);
      const token = params.get('token');
      const provider = params.get('provider');
      const providerId = params.get('providerId');
      
      if (token) {
        handleSnsCallback(token);
        
        // localStorage에 provider와 providerId 저장
        if (provider && providerId) {
          localStorage.setItem('provider', provider);
          localStorage.setItem('providerId', providerId);
        }
  
        navigate('/');
      } else {
        navigate('/login?error=auth_failed');
      }
    }, [location, handleSnsCallback, navigate]);
  
    return <div>인증 처리 중...</div>;
  }
  
  export default AuthCallback;