import axios from 'axios';
import { ENDPOINTS } from './endpoints';

export const login = async (email, password) => {
  try {
    // 로컬 스토리지에서 푸시 토큰과 플랫폼 정보 가져오기
    const pushToken = localStorage.getItem('pushToken');
    const platform = localStorage.getItem('platform');
    
    // 기본 페이로드 구성
    let payload = { email, password };
    
    // 푸시 토큰과 플랫폼 정보가 있으면 페이로드에 추가
    if (pushToken && platform) {
      payload = { ...payload, pushToken, platform };
    }
    
    // 로그인 API 호출
    const response = await axios.post(ENDPOINTS.AUTH.LOGIN, payload);
    return response.data;
  } catch (error) {
    // 에러 발생 시 에러 응답 데이터 throw
    throw error.response.data;
  }
};

export const signUp = async (email, password) => {
  try {
    // 회원가입 API 호출
    const response = await axios.post(ENDPOINTS.AUTH.SIGNUP, { email, password });
    return response.data;
  } catch (error) {
    // 에러 발생 시 에러 응답 데이터 throw
    throw error.response.data;
  }
};

export const snsLogin = async (provider, token) => {
  try {
    const upperProvider = provider.toUpperCase();
    const endpoint = ENDPOINTS.AUTH[upperProvider]?.INIT;

    if (!endpoint) {
      throw new Error(`Unsupported SNS provider: ${provider}`);
    }

    const response = await axios.post(endpoint, { token });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data;
    }
    throw error;
  }
};