import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';
import TopBar from './TopBar/TopBar';
import Menu from './Menu/Menu';
import Sidebar from './Sidebar/Sidebar';
import Bottom from './Bottom/Bottom';
import Popup from './Popup';
import Layer from './Layer/Layer';
import Modal from './Modal';
import { useAuth } from '../contexts/AuthContext';
import { useLayout } from '../contexts/LayoutContext';

const theme = {
  primary: '#3498db',
  secondary: '#2ecc71',
  background: '#f4f6f8',
  text: '#2c3e50',
  border: '#e0e0e0',
};

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    background-color: ${props => props.theme.background};
    color: ${props => props.theme.text};
  }
`;

const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
  background-color: ${props => props.theme.background};
  overflow: hidden;
`;

const Center = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  flex-grow: 1;
  transition: all 0.1s ease;   
  position: relative; 

  @media screen and (max-width: 1000px) {
    position: relative;
    left: ${props => props.$isMenuOpen ? '200px' : '0'};
    transition: left 0.1s ease;
  }  
`;

const Main = styled.div`
  position: relative;
  border: 1px solid ${props => props.theme.border};
  border-radius: 8px;
  margin: 10px;
  padding: 20px;
  overflow-y: auto;
  flex-grow: 1;  
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.1s ease;
  width: ${props => props.$isMenuOpen ? 'calc(100% - 200px)' : '100%'};

  @media screen and (max-width: 1000px) {
    margin: 10px;
    padding: 15px;
    width: calc(100vw - 60px);
    flex-shrink: 0;
  }  
`;

const Button = styled.button`
  cursor: pointer;
  padding: 10px 15px;
  background-color: ${props => props.theme.primary};
  color: white;
  border: none;
  border-radius: 5px;
  margin: 5px;
  display: inline-block;
  transition: all 0.3s ease;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  user-select:none;

  &:hover {
    background-color: ${props => props.theme.secondary};
    transform: translateY(-2px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
`;

function Layout({ children }) {  
  const navigate = useNavigate();
  const { layoutState, handlers } = useLayout();
  const { logout } = useAuth();

  const handleLogout = async () => {
    const success = await logout();
    if (success) {
      alert('로그아웃되었습니다.');
      navigate('/');
    } else {
      alert('로그아웃 중 오류가 발생했습니다.');
    }
    handlers.closeModal();
  };

  const handleLogoutClick = () => {
    handlers.openModal({
      title: '로그아웃',
      content: '정말로 로그아웃 하시겠습니까?',
      cancelButton: '취소',
      confirmButton: '확인',
      onConfirm: handleLogout,
    });
  };

  return (
    <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Wrapper>
            <TopBar 
              onMenuOpen={handlers.openMenu} 
              onMenuClose={handlers.closeMenu} 
              onLogout={handleLogoutClick}
              isMenuOpen={layoutState.isMenuOpen} 
              onSidebarOpen={handlers.openSidebar}
            />
            <Center>
                <Menu $isOpen={layoutState.isMenuOpen} />
                <Main $isMenuOpen={layoutState.isMenuOpen}>
                    {children}
                    <Button onClick={handlers.openBottom}>하단</Button>
                    <Button onClick={() => handlers.openSidebar('my')}>상태바</Button>
                    <Button onClick={() => handlers.openPopup("테스트 팝업", "알림")}>팝업 열기</Button>
                    <Button onClick={handlers.wrapLayerOpen}>wrapLayer열기</Button>
                    <Button onClick={handlers.mainLayerOpen}>mainLayer열기</Button>
                    {layoutState.mainLayers.map((layer) => (
                        <Layer 
                            key={layer.id} 
                            $type={layer.$type} 
                            onClose={() => handlers.closeMainLayer()} 
                            $isMainLayer 
                            onOpenNewLayer={handlers.mainLayerOpen}
                            layerExpandedStates={layoutState.layerExpandedStates}
                            setLayerExpandedStates={layoutState.setLayerExpandedStates}
                        />
                    ))}
                </Main>
                <Sidebar 
                  isOpen={layoutState.isSidebarOpen} 
                  activeIcon={layoutState.activeSidebarIcon} 
                  onClose={handlers.closeSidebar}
                  onIconClick={handlers.openSidebar}
                />
            </Center>            
            <Bottom $isOpen={layoutState.isBottomOpen} onClose={() => handlers.closeBottom()} />
            {layoutState.popup && <Popup {...layoutState.popup} onClose={() => handlers.closePopup()} />}
            {layoutState.wrapLayers.map((layer) => (
                <Layer 
                    key={layer.id} 
                    $type={layer.$type} 
                    onClose={() => handlers.closeWrapLayer()} 
                    onOpenNewLayer={handlers.wrapLayerOpen}
                    layerExpandedStates={layoutState.layerExpandedStates}
                    setLayerExpandedStates={layoutState.setLayerExpandedStates}
                />
            ))}
            <Modal 
              isOpen={layoutState.modalState.isOpen}
              onClose={() => handlers.closeModal()}
              title={layoutState.modalState.title}
              content={layoutState.modalState.content}
              cancelButton={layoutState.modalState.cancelButton}
              confirmButton={layoutState.modalState.confirmButton}
              onConfirm={layoutState.modalState.onConfirm}
              customContentStyle={layoutState.modalState.customContentStyle}
            />
        </Wrapper>
    </ThemeProvider>
  );
}

export default Layout;