import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { login as apiLogin, signUp as apiSignUp, snsLogin as apiSnsLogin } from '../api/auth';
import { ENDPOINTS } from '../api/endpoints';

const AuthContext = createContext(null);

// JWT 토큰을 파싱하는 함수
const parseJwt = (token) => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  } catch (error) {
    console.error('토큰 파싱 에러:', error);
    return null;
  }
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const checkTokenExpirationTime = useCallback(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = parseJwt(token);
        if (!decodedToken) {
          throw new Error('Invalid token');
        }
        const currentTime = Date.now() / 1000;
        const timeUntilExpiry = decodedToken.exp - currentTime;
        const twelveHoursInSeconds = 12 * 60 * 60;

        if (timeUntilExpiry <= 0) {
          // 토큰이 만료됨
          logout();
          return 'expired';
        } else if (timeUntilExpiry <= twelveHoursInSeconds) {
          // 토큰의 유효 기간이 12시간 이내로 남음
          return 'refresh';
        }
        return 'valid';
      } catch (error) {
        console.error('토큰 확인 에러:', error);
        logout();
        return 'error';
      }
    }
    return 'no-token';
  }, []);

  const refreshToken = useCallback(async () => {
    try {
      const response = await fetch('/api/refresh-token', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (response.ok) {
        const { token } = await response.json();
        updateToken(token);
        return true;
      }
      return false;
    } catch (error) {
      console.error('토큰 갱신 에러:', error);
      return false;
    }
  }, []);

  useEffect(() => {
    const tokenStatus = checkTokenExpirationTime();
    if (tokenStatus === 'valid' || tokenStatus === 'refresh') {
      const token = localStorage.getItem('token');
      setUser({ token });
      if (tokenStatus === 'refresh') {
        refreshToken();
      }
    }
  }, [checkTokenExpirationTime, refreshToken]);

  const updateToken = (newToken) => {
    localStorage.setItem('token', newToken);
    setUser({ token: newToken });
  };

  const login = async (email, password) => {
    try {
      const response = await apiLogin(email, password);
      updateToken(response.token);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const signUp = async (email, password) => {
    try {
      const response = await apiSignUp(email, password);
      updateToken(response.token);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const snsLogin = async (provider, token) => {
    try {
      const response = await apiSnsLogin(provider, token);
      if (!response.isNewUser) {
        updateToken(response.token);
      }
      return response;
    } catch (error) {
      throw error;
    }
  };

  const handleSnsCallback = (token) => {
    console.log('handleSnsCallback이 호출되었습니다. 토큰:', token);
    updateToken(token);
  };

  const logout = async () => {
    try {
      const response = await fetch(ENDPOINTS.AUTH.LOGOUT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (response.ok) {
        console.log('서버 로그아웃 성공');
      } else {
        console.log(`서버 로그아웃 실패: ${response.status} ${response.statusText}`);
      }
    } catch (error) {
      console.error('로그아웃 중 서버 통신 오류:', error);
    } finally {
      // 서버 응답과 관계없이 항상 로컬에서 로그아웃 처리
      localStorage.removeItem('token');
      setUser(null);
      return true; // 항상 로그아웃 성공으로 처리
    }
  };

  // API 요청을 보내는 함수
  const apiRequest = async (url, options = {}) => {
    const tokenStatus = checkTokenExpirationTime();
    
    if (tokenStatus === 'expired' || tokenStatus === 'no-token') {
      throw new Error('인증이 만료되었습니다. 다시 로그인해주세요.');
    }

    if (tokenStatus === 'refresh') {
      const refreshed = await refreshToken();
      if (!refreshed) {
        throw new Error('토큰 갱신에 실패했습니다. 다시 로그인해주세요.');
      }
    }

    const token = localStorage.getItem('token');
    if (token) {
      options.headers = {
        ...options.headers,
        'Authorization': `Bearer ${token}`
      };
    }

    const response = await fetch(url, options);
    
    if (response.status === 401) {
      logout();
      throw new Error('인증이 만료되었습니다. 다시 로그인해주세요.');
    }

    return response;
  };

  const authContextValue = {
    user,
    login,
    logout,
    signUp,
    snsLogin,
    handleSnsCallback,
    apiRequest,
    checkTokenExpirationTime,
    refreshToken
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};