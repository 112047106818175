import { useState, useEffect } from 'react';

export default function useLayoutState() {
  // 메뉴의 열림/닫힘 상태를 관리
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  
  // 하단 영역의 열림/닫힘 상태를 관리
  const [isBottomOpen, setIsBottomOpen] = useState(false);
  
  // 팝업의 내용과 표시 여부를 관리 (null이면 팝업 없음)
  const [popup, setPopup] = useState(null);

  // 사이드바 마지막 아이콘 상태
  const [lastSidebarIcon, setLastSidebarIcon] = useState(null);
  
  // 전체 화면 레이어들의 상태를 관리 (배열로 여러 개 관리 가능)
  const [wrapLayers, setWrapLayers] = useState([]);
  
  // 메인 영역 내 레이어들의 상태를 관리 (배열로 여러 개 관리 가능)
  const [mainLayers, setMainLayers] = useState([]);

  // Layer의 확장 상태를 저장하는 객체
  const [layerExpandedStates, setLayerExpandedStates] = useState(() => {
    const savedStates = localStorage.getItem('layerExpandedStates');
    return savedStates ? JSON.parse(savedStates) : {};
  });

  // layerExpandedStates가 변경될 때마다 localStorage에 저장
  useEffect(() => {
    localStorage.setItem('layerExpandedStates', JSON.stringify(layerExpandedStates));
  }, [layerExpandedStates]);  

  // 사이드바의 열림/닫힘 상태를 관리
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  
  // 현재 활성화된 사이드바 아이콘을 관리
  const [activeSidebarIcon, setActiveSidebarIcon] = useState('profile');

  // 사이드바 새로고침 키
  const [sidebarRefreshKey, setSidebarRefreshKey] = useState(0);

  // 모달 상태 추가
  const [modalState, setModalState] = useState({
    isOpen: false,
    title: '',
    content: '',
    cancelButton: '취소',
    confirmButton: '확인',
    onConfirm: () => {},
  });  

  // 각 상태와 그 상태를 변경하는 함수들을 객체로 반환
  return {
    isMenuOpen,
    setIsMenuOpen,
    isBottomOpen,
    setIsBottomOpen,
    popup,
    setPopup,
    lastSidebarIcon,
    setLastSidebarIcon,
    wrapLayers,
    setWrapLayers,
    mainLayers,
    setMainLayers,
    layerExpandedStates,
    setLayerExpandedStates,
    isSidebarOpen,
    setIsSidebarOpen,
    activeSidebarIcon,
    setActiveSidebarIcon,
    sidebarRefreshKey,
    setSidebarRefreshKey,
    modalState,
    setModalState,
  };
}

// 특정 컴포넌트에 대한 뒤로가기 처리:
// 1. 해당 컴포넌트가 열릴 때 브라우저 히스토리에 상태를 추가합니다.
// 2. 뒤로가기 이벤트 발생 시, 해당 컴포넌트를 닫는 로직을 실행합니다.
// 3. 이를 위해 useNavigationHandlers 훅에서 popstate 이벤트를 감지하고 처리합니다.

// 예시: 팝업에 대한 뒤로가기 처리
// openPopup 함수에서:
// navigate(`${location.pathname}?popup=open`);
// addToCloseStack(() => closePopup(true), 'popup');

// closePopup 함수에서:
// if (!backBtn) {
//   isPopStateRef.current = true;
//   navigate(-1);
//   removeFromCloseStack('popup');
// }

// 이렇게 하면 팝업이 열릴 때 URL이 변경되고, 
// 뒤로가기 시 closePopup 함수가 실행되어 팝업이 닫힙니다.