import styled from 'styled-components';
import { MdCallMade, MdCallReceived } from 'react-icons/md';

const SidebarWrapper = styled.div`
  display: flex;
  width: ${props => props.$isOpen ? `${props.$width}px` : '60px'};
  height: calc(100% - 20px);
  margin: 10px 0;
  background-color: #2c3e50;
  color: #ecf0f1;
  transition: all 0.1s ease;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1);
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  overflow: hidden;
  flex-shrink:0;

  @media (max-width: 1000px) {
    display: ${props => props.$isOpen ? 'flex' : 'none'};
    position: fixed;
    top: 0px;
    margin-top: 0px;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    border-radius: 0px;
  }
`;

const IconBar = styled.div`
  width: 60px;
  min-width: 60px;
  background-color: #34495e;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
`;

const IconButton = styled.button`
  background: none;
  border: none;
  color: ${props => props.$isOpen && props.$active ? '#3498db' : '#ecf0f1'};
  font-size: 24px;
  cursor: pointer;
  padding: 15px 0;
  width: 100%;
  transition: all 0.2s ease;
  user-select: none;
  position: relative;

  &:hover {
    background-color: ${props => props.$isOpen ? '#2c3e50' : 'transparent'};
    color: ${props => props.$isOpen ? '#3498db' : '#ecf0f1'};
  }
`;

const ContentArea = styled.div`
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
  background-color: white;
  display: block;
`;

const ContentText = styled.p`
  font-size: 16px;
  line-height: 1.5;
  color: #333;
`;

const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const ContentTitle = styled.h2`
  font-size: 24px;
  font-weight: 600;
  color: #2c3e50;
  user-select: none;
`;

const HeaderButtons = styled.div`
  display: flex;
  gap: 10px;
`;

const ResizeButton = styled.button`
  background: none;
  border: none;
  color: #7f8c8d;
  font-size: 20px;
  cursor: pointer;
  transition: color 0.1s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: #34495e;
  }

  @media (max-width: 1000px) {
    display: none;
  }
`;

const FlippedIcon = styled.span`
  display: inline-flex;
  transform: scaleX(-1);
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: #7f8c8d;
  font-size: 20px;
  cursor: pointer;
  transition: color 0.2s ease;

  &:hover {
    color: #34495e;
  }
`;

const NotificationBadge = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #e74c3c;
  color: white;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NewChatBadge = styled(NotificationBadge)`
  background-color: #2ecc71;
`;

export {
  SidebarWrapper,
  IconBar,
  IconButton,
  ContentArea,
  ContentText,
  ContentHeader,
  ContentTitle,
  HeaderButtons,
  ResizeButton,
  FlippedIcon,
  CloseButton,
  NotificationBadge,
  NewChatBadge
};