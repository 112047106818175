import React, { useState, useEffect } from 'react';
import { FaUser, FaBell, FaComments, FaCog, FaTimes } from 'react-icons/fa';
import { MdCallMade, MdCallReceived } from 'react-icons/md';
import My from './My/My';
import Notifications from './Notifications/Notifications';
import Chat from './Chat/Chat';
import Settings from './Settings/Settings';
import {
  SidebarWrapper,
  IconBar,
  IconButton,
  ContentArea,
  ContentHeader,
  ContentTitle,
  HeaderButtons,
  ResizeButton,
  FlippedIcon,
  CloseButton,
  NotificationBadge,
  NewChatBadge
} from './SidebarStyles';


function Sidebar({ isOpen, activeIcon, onClose, onIconClick, sidebarRefreshKey }) {
  const [width, setWidth] = useState(500);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1000);

  useEffect(() => { // 사이드바 새로고침
    if (isOpen) {
      // 여기에 데이터 리로드 로직을 추가합니다.
      // 예: fetchData(activeIcon);
    }
  }, [sidebarRefreshKey, isOpen, activeIcon]);

  useEffect(() => { // 사이드바 크기 조절
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1000);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleIncrease = () => { // 사이드바 크기 증가
    setWidth(prevWidth => Math.min(prevWidth + 200, 1000));
  };

  const handleDecrease = () => { // 사이드바 크기 감소
    setWidth(prevWidth => Math.max(prevWidth - 200, 500));
  };

  const notificationCount = 12;
  const hasNewChat = true;

  const handleIconClick = (icon, type = null) => {
    onIconClick(icon, type); // type 이 self 이면 sidebar 에서 클릭, type 이 null 이면 다른 탭에서 클릭
  };

  const renderContent = () => {
    const contentTitle = activeIcon.toUpperCase();

    return (
      <>
        <ContentHeader>
          <ContentTitle>{contentTitle}</ContentTitle>
          <HeaderButtons>
            <ResizeButton onClick={handleIncrease} title="크게">
              <FlippedIcon><MdCallMade /></FlippedIcon>
            </ResizeButton>
            <ResizeButton onClick={handleDecrease} title="작게">
              <FlippedIcon><MdCallReceived /></FlippedIcon>
            </ResizeButton>                
            <CloseButton onClick={() => onClose()}><FaTimes /></CloseButton>
          </HeaderButtons>
        </ContentHeader>
        {activeIcon === 'my' && <My key={sidebarRefreshKey} />}
        {activeIcon === 'notifications' && <Notifications key={sidebarRefreshKey} />}
        {activeIcon === 'chat' && <Chat key={sidebarRefreshKey} />}
        {activeIcon === 'settings' && <Settings key={sidebarRefreshKey} />}
      </>
    );
  };

  return (
    <SidebarWrapper $isOpen={isOpen} $width={width}>
      <IconBar>
        <IconButton $isOpen={isOpen} $active={activeIcon === 'my'} onClick={() => handleIconClick('my','self')}>
          <FaUser />
        </IconButton>
        <IconButton $isOpen={isOpen} $active={activeIcon === 'notifications'} onClick={() => handleIconClick('notifications','self')}>
          <FaBell />
          {notificationCount > 0 && (
            <NotificationBadge>
              {notificationCount > 9 ? '9+' : notificationCount}
            </NotificationBadge>
          )}
        </IconButton>
        <IconButton $isOpen={isOpen} $active={activeIcon === 'chat'} onClick={() => handleIconClick('chat','self')}>
          <FaComments />
          {hasNewChat && <NewChatBadge>N</NewChatBadge>}
        </IconButton>
        <IconButton $isOpen={isOpen} $active={activeIcon === 'settings'} onClick={() => handleIconClick('settings','self')}>
          <FaCog />
        </IconButton>
      </IconBar>
      {isOpen && (
        <ContentArea>
          {renderContent()}
        </ContentArea>
      )}
    </SidebarWrapper>
  );
}

export default Sidebar;