import React, { useState, useEffect, useCallback } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { FaBars, FaTimes, FaExpandAlt, FaCompressAlt, FaUser, FaClipboard, FaCog } from 'react-icons/fa';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const LayerWrapper = styled.div`
  position: ${props => props.$isMainLayer ? 'absolute' : 'fixed'};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: ${props => props.$isMainLayer ? 'flex-start' : 'center'};
  z-index: 10;
  animation: ${fadeIn} 0.1s ease-out;

  &.white {
    background: rgba(255, 255, 255, 0.1);
  }
`;

const LayerContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  transition: all 0.1s ease;
  height: ${props => props.$isMainLayer || props.$type === 'wrap' ? 'calc(100% - 40px)' : 'calc(100% - 40px)'};
  min-height: 300px;
  width: ${props => props.$isExpanded ? 'calc(100% - 40px)' : '80%'};
  max-width: ${props => props.$isExpanded ? 'none' : '1200px'};

  ${props => props.$isMainLayer || props.$type === 'wrap' ? css`
    margin: 20px;
  ` : css`
    max-height: calc(100% - 40px);
  `}

  @media screen and (max-width: 1000px) {
    width: ${props => props.$isExpanded ? '100%' : 'calc(100% - 40px)'} !important;
    height: ${props => props.$isExpanded ? '100%' : 'calc(100% - 40px)'} !important;
    margin: ${props => props.$isExpanded ? '0' : '20px'};
    border-radius: ${props => props.$isExpanded ? '0' : '8px'};
  }
`;

const IconButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: 18px;
  cursor: pointer;
  padding: 5px;
  margin-left: 10px;
  transition: all 0.1s ease;

  &:hover {
    transform: scale(1.1);
  }
`;

const LayerTop = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 20px;
  background-color: #2c3e50;
  color: #fff;
  user-select: none;

  &.hidden {
    display: none;
  }
`;

const LayerTitle = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  font-weight: 600;
  font-size: 18px;
`;

const MenuButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: 18px;
  cursor: pointer;
  padding: 5px;
  margin-right: 10px;
  transition: all 0.1s ease;

  &:hover {
    transform: scale(1.1);
  }
`;

const LayerCenter = styled.div`
  display: flex;
  flex-grow: 1;
  background-color: #f8f9fa;
  overflow: hidden;
`;

const LayerCenterLeft = styled.div`
  min-width: 220px;
  background-color: #34495e;
  color: #fff;
  overflow-y: auto;
  transition: all 0.1s ease;
  user-select: none;
  -webkit-overflow-scrolling: touch; // 모바일 디바이스에서 부드러운 스크롤을 위해

  /* 스크롤바 숨기기 (선택적) */
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  // IE and Edge
  scrollbar-width: none;  // Firefox  

  @media (max-width: 1000px) {
    min-width: 50px;
  }

  &.hidden {
    margin-left: -220px;

    @media (max-width: 1000px) {
      margin-left: -50px;
    }
  }
`;

const MenuIcon = styled.span`
  display: inline-flex;
  align-items: center;
  margin-right: 10px;
  
  @media (max-width: 1000px) {
    margin-right: 0;
    margin-bottom:10px;
  }
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.1s;
  background-color: ${props => props.$active ? '#2c3e50' : 'transparent'};

  @media (max-width: 1000px) {
    padding: 10px;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &:hover {
    background-color: #2c3e50;
  }
`;

const MenuText = styled.span`
  @media (max-width: 1000px) {
    writing-mode: vertical-lr;
    text-orientation: mixed;
    white-space: nowrap;
  }
`;

const LayerCenterMain = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const LayerCenterMainMenu = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f8f9fa;
  border-bottom: 1px solid #e9ecef;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  user-select: none;
`;

const SubMenuContainer = styled.div`
  position: relative;
  height: 45px;
  background-color: #fff;
  color: #333;
  font-size: 12px;
  font-weight: 800;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch; // 모바일 디바이스에서 부드러운 스크롤을 위해

  /* 스크롤바 숨기기 (선택적) */
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  // IE and Edge
  scrollbar-width: none;  // Firefox
`;

const SubMenuItem = styled.div`
  display: inline-block;
  min-width: 70px;
  padding: 5px 10px 10px;
  text-align: center;  
  vertical-align: top;
  font-weight: ${props => props.$active ? '800' : '400'};
  font-size: ${props => props.$active ? '14px' : '12px'};
  line-height: 45px;

  &:hover {
    font-weight: 800;
    font-size: 14px;
    cursor:pointer;
  }
`;

const LayerCenterMainContent = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px;
`;

const LayerBottom = styled.div`
  padding: 15px 20px;
  background-color: #ecf0f1;
  border-top: 1px solid #bdc3c7;
`;

const NewLayerButton = styled.button`
  padding: 8px 15px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.1s ease;
  
  &:hover {
    background-color: #2980b9;
  }

  &:disabled {
    background-color: #95a5a6;
    cursor: not-allowed;
  }
`;

function Layer({ 
  $type,
  onClose,
  $isMainLayer = false,
  onOpenNewLayer,
  $showCloseButton = true,
  $showMenuButton = true,
  $showBottomContent = true,
  $showMainMenu = true,
  $showResizeButton = true,
  $initialExpanded = false,
  layerExpandedStates,
  setLayerExpandedStates,  
}) {
    const [isExpanded, setIsExpanded] = useState(() => {
      return layerExpandedStates[$type] ?? $initialExpanded;
    });
    const [layerOptions, setLayerOptions] = useState({
      $showCloseButton,
      $showMenuButton,
      $showBottomContent,
      $showMainMenu,
      $showResizeButton,
      $initialExpanded
    });

    const [leftMenuHidden, setLeftMenuHidden] = useState(false);
    //const [isExpanded, setIsExpanded] = useState($initialExpanded);

    const [activeMenu, setActiveMenu] = useState(null);
    const [activeSubMenu, setActiveSubMenu] = useState(null);

    const menus = [
      { id: 'profile', name: '프로필', icon: FaUser, subMenus: ['개인정보', '보안설정', '테스트'] },
      { id: 'orders', name: '주문관리', icon: FaClipboard, subMenus: ['주문목록', '배송현황'] },
      { id: 'settings', name: '설정', icon: FaCog, subMenus: ['일반설정', '알림설정'] },
    ];
 
    const toggleLayerSize = useCallback(() => {
      const newExpandedState = !isExpanded;
      setIsExpanded(newExpandedState);
      setLayerExpandedStates(prev => {
        const newStates = {
          ...prev,
          [$type]: newExpandedState
        };
        localStorage.setItem('layerExpandedStates', JSON.stringify(newStates));
        return newStates;
      });
    }, [isExpanded, $type, setLayerExpandedStates]);

    // 컴포넌트가 마운트될 때 localStorage에서 상태를 읽어옴
    useEffect(() => {
      const savedStates = localStorage.getItem('layerExpandedStates');
      if (savedStates) {
        const parsedStates = JSON.parse(savedStates);
        if (parsedStates[$type] !== undefined) {
          setIsExpanded(parsedStates[$type]);
        }
      }
    }, [$type]);    

    useEffect(() => {
      if (layerOptions.$showMenuButton && menus.length > 0) {
        setActiveMenu(menus[0].id);
        if (layerOptions.$showMainMenu && menus[0].subMenus.length > 0) {
          setActiveSubMenu(menus[0].subMenus[0]);
        }
      } else if (layerOptions.$showMainMenu && menus.length > 0 && menus[0].subMenus.length > 0) {
        setActiveSubMenu(menus[0].subMenus[0]);
      }
    }, []);
  
    const handleMenuClick = (menuId) => {
      setActiveMenu(menuId);
      const selectedMenu = menus.find(menu => menu.id === menuId);
      if (layerOptions.$showMainMenu && selectedMenu.subMenus.length > 0) {
        setActiveSubMenu(selectedMenu.subMenus[0]);
      } else {
        setActiveSubMenu(null);
      }
    };

    const handleSubMenuClick = (subMenu) => {
      setActiveSubMenu(subMenu);
    };

    const renderContent = () => {
      if (!activeSubMenu && !activeMenu) return <p>기본 콘텐츠입니다.</p>;

      if (activeSubMenu) {
        switch (activeSubMenu) {
        case '개인정보':
          return <p>여기에 사용자의 개인정보 양식이 표시됩니다.</p>;
        case '보안설정':
          return <p>비밀번호 변경 및 2단계 인증 설정 옵션이 여기에 표시됩니다.</p>;
        case '주문목록':
          return <p>최근 주문 내역 및 상세 정보가 여기에 나열됩니다.</p>;
        case '배송현황':
          return <p>현재 진행 중인 배송의 실시간 추적 정보가 여기에 표시됩니다.</p>;
        case '일반설정':
          return <p>언어, 시간대 등의 일반적인 설정 옵션이 여기에 표시됩니다.</p>;
        case '알림설정':
          return <p>이메일, 푸시 알림 등의 알림 설정 옵션이 여기에 표시됩니다.</p>;
        default:
          return <p>선택된 메뉴: {activeSubMenu}</p>;
        }
      } else if (activeMenu) {
          return <p>선택된 메뉴: {menus.find(menu => menu.id === activeMenu).name}</p>;
      }
    };
  
    const toggleLeftMenu = () => {
      setLeftMenuHidden(!leftMenuHidden);
    };

    const handleClose = (e) => {
      //console.log('Close button clicked');
      e.stopPropagation();
      if (onClose) {
        //console.log('Calling onClose function');
        onClose();
      } else {
        //console.log('onClose function is not defined');
      }
    };
  
    const handleWrapperDoubleClick = (e) => {
      if (e.target === e.currentTarget && layerOptions.$showCloseButton) {
        handleClose(e);
      }
    };
  
    const getSelectedMenuName = () => {
      const selectedMenu = menus.find(menu => menu.id === activeMenu);
      return selectedMenu ? selectedMenu.name : '';
    };

    const renderIcon = (Icon) => (
      <MenuIcon>
        <Icon />
      </MenuIcon>
    );

    return (
      <LayerWrapper 
        $isMainLayer={$isMainLayer}
        $type={$type}
        $isExpanded={isExpanded}
        onDoubleClick={handleWrapperDoubleClick}
      >
        <LayerContent 
          $isMainLayer={$isMainLayer}
          $type={$type}
          $isExpanded={isExpanded}
        >
          <LayerTop $type={$type}>
            <LayerTitle>
              {layerOptions.$showMenuButton && (
                <MenuButton onClick={toggleLeftMenu}>
                  <FaBars />
                </MenuButton>
              )}
              타이틀
            </LayerTitle>
            {layerOptions.$showResizeButton && (
              <IconButton onClick={toggleLayerSize}>
                {isExpanded ? <FaCompressAlt /> : <FaExpandAlt />}
              </IconButton>
            )}
            {layerOptions.$showCloseButton && (
              <IconButton onClick={handleClose}><FaTimes /></IconButton>
            )}
          </LayerTop>
        <LayerCenter>
          {layerOptions.$showMenuButton && (
            <LayerCenterLeft className={leftMenuHidden ? "hidden" : ""} $type={$type}>
              {menus.map((menu) => (
                <MenuItem 
                  key={menu.id} 
                  onClick={() => handleMenuClick(menu.id)}
                  $active={activeMenu === menu.id}
                >
                  {renderIcon(menu.icon)}
                  <MenuText>{menu.name}</MenuText>
                </MenuItem>
              ))}
            </LayerCenterLeft>
          )}
          <LayerCenterMain>
          {layerOptions.$showMainMenu && (
            <LayerCenterMainMenu>
              <SubMenuContainer>
                {activeMenu && menus.find(menu => menu.id === activeMenu)?.subMenus.map((subMenu) => (
                  <SubMenuItem 
                    key={subMenu} 
                    onClick={() => handleSubMenuClick(subMenu)}
                    $active={activeSubMenu === subMenu}
                  >
                    {subMenu}
                  </SubMenuItem>
                ))}
              </SubMenuContainer>
            </LayerCenterMainMenu>
          )}
          <LayerCenterMainContent>
            {renderContent()}
            <NewLayerButton onClick={onOpenNewLayer}>새 레이어 열기</NewLayerButton>
          </LayerCenterMainContent>
          </LayerCenterMain>
        </LayerCenter>
          {layerOptions.$showBottomContent && (
            <LayerBottom>
              Bottom Content
            </LayerBottom>
          )}
        </LayerContent>
      </LayerWrapper>
    );
  }
  
  export default Layer;