import React, { createContext, useContext } from 'react';
import useLayoutState from '../hooks/useLayoutState';
import useNavigationHandlers from '../hooks/useNavigationHandlers';

const LayoutContext = createContext();

export const LayoutProvider = ({ children }) => {
  const layoutState = useLayoutState();
  const handlers = useNavigationHandlers(layoutState);

  return (
    <LayoutContext.Provider value={{ layoutState, handlers }}>
      {children}
    </LayoutContext.Provider>
  );
};

export const useLayout = () => {
  const context = useContext(LayoutContext);
  if (!context) {
    throw new Error('useLayout must be used within a LayoutProvider');
  }
  return context;
};